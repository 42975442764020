<template>
  <div class="purchased-container">
    <div v-if="isMoreDataLoading">
      <Loading></Loading>
    </div>
    <div class="purchase listing" v-if="purchasedContent && purchasedContent.length > 0">
      <p class="category-name">{{$t("PURCHASED/RENTAL")}}</p>
      <div class="content-details" v-for="(content, index) in purchasedContent" :key="index">
        <div class="display-info" @click="() => actRedirection(content)">
          <img class="content-picture" v-if="content.picture" :src="content.picture" alt />
          <div class="content-info">
            <p class="bold-text"> {{ content.itemTitle }}</p>
            <p class="light-text">Invoiceid: {{ content.invoiceid }}</p>

            <p class="light-text">{{ $t('Expires on') }}: {{ getFormatedDate(content.expiry) }}</p>

            <p class="light-text" v-html="currencyCodeSubscriber(content)"></p>
          </div>
        </div>
        <!-- <img class="pdf-report" src="@/assets/icons/pdf-1.svg" alt /> -->
      </div>
    </div>
    <div v-else-if="!isMoreDataLoading" class="data-not-found">
       {{ $t("No Data Found") }}
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store";
import Utility from "@/mixins/Utility.js";

export default {
  data() {
    return {
      category: "",
      categoryName: "TV Shows",
      purchasedContent: null,
      localDisplayLang: "",
      isMoreDataLoading: true
    };
  },
  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.fetchPurchaseList();
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue")
  },
  methods: {
    currencyCodeSubscriber (plan) {
      return  plan.currency == "INR" ? "<span>&#8377;</span>" + " " + (plan.amount / 100) : "<span>&#36;</span>" + " " + (plan.amount / 100);
    },
    actRedirection (content) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if(content.category == "TVSHOW"){
             let title = this.spacialCharEncoding(content.defaulttitle || content.title);
            this.$router.push({ name: "detailPage", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title,lang: currentLanguage   } });

          } else if ( content.category == 'SHORTS') {
            let title = this.spacialCharEncoding(content.defaulttitle || content.title);

            this.$router.push({
              name: "sainashorts",
              params: { contentId: content.objectid.toLowerCase(), mediaTitle: title, lang: currentLanguage }
            });
          } else {
           let title = this.spacialCharEncoding(content.defaulttitle || content.title);
            this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid.toLowerCase() ,lang: currentLanguage  } });
          }
    },
    getFormatedDate(d) {
      try {
          let newDate = new Date(d);
          return newDate.getDate() + " " + newDate.toLocaleString('default', {
              month: 'short'
          }) + " " + newDate.getFullYear();
      } catch (e) {
          return d;
      }

    },
     getContentDetails (objectid) {
      let payload = {
            contentid: objectid,
            params: {
              displaylanguage: this.localDisplayLang,
            },
          };
        return store.dispatch("contentDetail", payload);

    },
     fetchPurchaseList() {
      store
        .dispatch("listPurchases")
        .then(async (response) => {
          if (!response.data.reason) {
            store.commit("setPurchasesList", response.data.data);
            let itemList = response.data.data;

            for (let rentalItem in itemList) {
               let dataset = await this.getContentDetails(itemList[rentalItem].objectid)

               itemList[rentalItem].picture = dataset.data.thumbnail;
               itemList[rentalItem].itemTitle = dataset.data.title;
               itemList[rentalItem].category = dataset.data.category;
               itemList[rentalItem].title = dataset.data.title;
               itemList[rentalItem].defaulttitle = dataset.data.title;

               




            }
            this.isMoreDataLoading = false;
            this.purchasedContent = itemList 

            console.error("this.purchasedConten", this.purchasedContent)


          } else {
            this.isMoreDataLoading = false;
          }
        })
        .catch((error) => {
          this.isMoreDataLoading = false;
        });
    },
  },
  mixins: [Utility],

};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.purchased-container {
  padding: 1rem 2rem 2rem;
  position: relative;
  .category-select {
    width: fit-content;
    position: absolute;
    right: 4%;
    top: 7%;
    background-color: transparent;
    padding: 6px;
    border-radius: 4px;
    color: $clr-light-gd2;
    border: 1px solid $clr-light-gd4;
  }
  .category-name {
    color: $clr-popup-para;
    font-weight: 600;
    font-size: 0.9rem;
  }
}
.content-details {
  display: flex;
  align-items: center;
  // background-color: $clr-dark-gd4;
  border-radius: 10px;
  justify-content: space-between;
  // margin: 0.5rem 0rem;
  padding: 1rem;
  .display-info {
    cursor: pointer;
    display: flex;
    .content-picture {
      width: 16rem;
      height: 9rem;
    }
    .content-info {
      line-height: 15px;
      padding: 0.8rem 0rem 0rem 1rem;
      .bold-text {
        color: $clr-saina-light-dark-txt;
        font-weight: 600;
        margin-bottom: 0.8em;

      }
      .light-text {
        color: $clr-saina-light-dark-txt;
        font-size: 0.8em;
        margin-bottom: 0.8em;
      }
    }
  }
  .pdf-report {
    width: 15px;
    height: 17px;
  }
}
.data-not-found {
  color: $clr-sbsciberemail-txt;;
}
</style>
